import React from "react";
import RestaurantList from './restaurants/RestaurantList'
import SearchAppBar from './SearchAppBar'
import { Link } from "react-router-dom";

const RestaurantsPage = () => {
    const buttonStyle = {
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '20px',
        cursor: 'pointer',
        fontSize: '14px',
        fontWeight: 'bold',
        marginRight: '10px',
        textDecoration: 'none',
        display: 'inline-block'
    };

    return (
        <>
            <SearchAppBar/>
            <RestaurantList/>
            <div style={{textAlign: "center", marginTop: "20px", marginBottom: "30px"}}>
                <Link to="/acknowledgements/terms-conditions" style={{ textDecoration: 'none' }}>
                    <button style={buttonStyle}>Termeni și Condiții</button>
                </Link>
                <Link to="/acknowledgements/privacy-policy" style={{ textDecoration: 'none' }}>
                    <button style={buttonStyle}>Politica de Confidențialitate</button>
                </Link>
            </div>
        </>
    )
}

export default RestaurantsPage;
