import React, { useEffect, useState } from 'react';

const TermsConditionsPage = () => {
  const [currentLang, setCurrentLang] = useState('ro');

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLang = localStorage.getItem('lang_terms');
    if (storedLang) {
      setCurrentLang(storedLang);
    }
  }, []);

  const toggleLanguage = (lang) => {
    setCurrentLang(lang);
    localStorage.setItem('lang_terms', lang);
  };

  const sliderStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '80px',
    height: '30px',
    background: '#ccc',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    userSelect: 'none'
  };

  const circleStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: 'green',
    transition: 'left 0.3s',
    left: currentLang === 'ro' ? '0px' : '50px'
  };

  const textStyle = (lang) => ({
    position: 'relative',
    zIndex: 1,
    color: currentLang === lang ? '#fff' : '#000',
    fontWeight: 'bold',
    fontSize: '12px'
  });

  return (
    <div style={{ position: 'relative', padding: "20px" }}>
      <div style={sliderStyle}>
        <div style={circleStyle}></div>
        <span onClick={() => toggleLanguage('ro')} style={textStyle('ro')}>RO</span>
        <span onClick={() => toggleLanguage('en')} style={textStyle('en')}>EN</span>
      </div>

      {currentLang === 'ro' && (
        <div className="ro">
          <h1>Termeni și Condiții</h1>
          <p><strong>Data Intrării În Vigoare:</strong> 15 decembrie 2024</p>
          <p>Bine ați venit la Reach! Acești Termeni și Condiții ("Termeni") reglementează utilizarea aplicației Reach ("Aplicație"), operată de Forest Flow Software S.R.L. ("Companie," "noi," "ne," sau "nostru"). Prin accesarea sau utilizarea Aplicației, sunteți de acord să respectați acești Termeni. Dacă nu sunteți de acord, nu utilizați Aplicația.</p>

          <h2>1. Eligibilitate</h2>
          <p>Prin utilizarea Aplicației, declarați că aveți cel puțin 16 ani sau ați atins vârsta majoratului conform jurisdicției dvs. și că aveți capacitatea legală de a fi de acord cu acești Termeni.</p>

          <h2>2. Conturi de Utilizator</h2>
          <ul>
            <li>Trebuie să furnizați informații exacte și complete, inclusiv adresa dvs. de email.</li>
            <li>Sunteți responsabil pentru păstrarea confidențialității datelor dvs. de autentificare.</li>
            <li>Sunteți de acord să ne informați imediat despre orice utilizare neautorizată a contului dvs.</li>
          </ul>

          <h2>3. Utilizarea Aplicației</h2>
          <ul>
            <li>Sunteți de acord să utilizați Aplicația doar în scopuri legale și în conformitate cu acești Termeni.</li>
            <li>Vă este interzis să participați la orice activitate care perturbă sau interferează cu funcționarea Aplicației.</li>
            <li>Nu puteți încerca să obțineți acces neautorizat la orice sisteme sau rețele.</li>
            <li>Nu puteți utiliza Aplicația în scopuri comerciale fără consimțământul nostru prealabil, în scris.</li>
          </ul>

          <h2>4. Proprietate Intelectuală</h2>
          <p>Aplicația și conținutul său original, funcționalitățile și caracteristicile sale sunt și vor rămâne proprietatea exclusivă a Forest Flow Software S.R.L. și a licențiatorilor săi. Vi se acordă o licență limitată, neexclusivă, netransferabilă pentru a accesa și utiliza Aplicația conform acestor Termeni.</p>

          <h2>5. Date Generat de Utilizatori</h2>
          <p>Când utilizați Aplicația, este posibil să introduceți preferințe și alte date. Procedând astfel, ne acordați o licență neexclusivă, la nivel mondial, scutită de redevențe, pentru a utiliza, stoca și procesa aceste date exclusiv pentru a furniza și a îmbunătăți funcționalitatea Aplicației.</p>

          <h2>6. Încheierea Utilizării</h2>
          <p>Putem înceta sau suspenda accesul dvs. la Aplicație, la discreția noastră exclusivă, fără notificare prealabilă, dacă încălcați acești Termeni sau din orice alt motiv.</p>

          <h2>7. Limitarea Răspunderii</h2>
          <p>În limita maximă permisă de lege, Forest Flow Software S.R.L. nu va fi răspunzătoare pentru daune indirecte, incidentale, speciale, consecutive sau punitive, sau pentru pierderea profiturilor sau veniturilor, fie ele directe sau indirecte, sau pentru pierderea datelor, utilizării, bunăvoinței sau altor pierderi intangibile care rezultă din:</p>
          <ul>
            <li>Utilizarea sau incapacitatea de a utiliza Aplicația.</li>
            <li>Accesul neautorizat, utilizarea sau modificarea transmisiilor sau conținutului dvs.</li>
          </ul>

          <h2>8. Legea Guvernantă</h2>
          <p>Acești Termeni sunt guvernați de legile din [Introduceți Jurisdicția]. Orice dispută care rezultă din acești Termeni va fi supusă jurisdicției exclusive a instanțelor din [Introduceți Jurisdicția].</p>

          <h2>9. Modificări ale Termenilor</h2>
          <p>Ne rezervăm dreptul de a modifica acești Termeni în orice moment. Vom furniza notificări cu privire la modificări actualizând "Data Intrării În Vigoare" de mai sus. Continuarea utilizării Aplicației constituie acceptarea Termenilor revizuiți.</p>

          <h2>10. Informații de Contact</h2>
          <p>Pentru întrebări sau nelămuriri legate de acești Termeni, vă rugăm să ne contactați la:</p>
          <p><strong>Forest Flow Software S.R.L.</strong><br/>Email: support@forestflowsoft.com</p>
        </div>
      )}

      {currentLang === 'en' && (
        <div className="en">
          <h1>Terms and Conditions</h1>
          <p><strong>Effective Date:</strong> December 15, 2024</p>
          <p>Welcome to <em>Reach</em>! These Terms and Conditions ("Terms") govern your use of the Reach application ("App"), operated by Forest Flow Software S.R.L. ("Company," "we," "us," or "our"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree, you may not use the App.</p>

          <h2>1. Eligibility</h2>
          <p>By using the App, you represent that you are at least 16 years old or have reached the age of majority in your jurisdiction, and you have the legal capacity to agree to these Terms.</p>

          <h2>2. User Accounts</h2>
          <ul>
            <li>You must provide accurate and complete information, including your email address.</li>
            <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
            <li>You agree to notify us immediately of any unauthorized use of your account.</li>
          </ul>

          <h2>3. Use of the App</h2>
          <ul>
            <li>You agree to use the App only for lawful purposes and in accordance with these Terms.</li>
            <li>You are prohibited from engaging in any activity that disrupts or interferes with the App’s operation.</li>
            <li>You may not attempt to gain unauthorized access to any systems or networks.</li>
            <li>You may not use the App for any commercial purposes without our prior written consent.</li>
          </ul>

          <h2>4. Intellectual Property</h2>
          <p>The App and its original content, features, and functionality are and will remain the exclusive property of Forest Flow Software S.R.L. and its licensors. You are granted a limited, non-exclusive, non-transferable license to access and use the App in accordance with these Terms.</p>

          <h2>5. User-Generated Data</h2>
          <p>When using the App, you may input preferences and other data. By doing so, you grant us a non-exclusive, worldwide, royalty-free license to use, store, and process this data solely to provide and improve the App’s functionality.</p>

          <h2>6. Termination</h2>
          <p>We may terminate or suspend your access to the App at our sole discretion, without prior notice, if you violate these Terms or for any other reason.</p>

          <h2>7. Limitation of Liability</h2>
          <p>To the fullest extent permitted by law, Forest Flow Software S.R.L. shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p>
          <ul>
            <li>Your use or inability to use the App.</li>
            <li>Unauthorized access, use, or alteration of your transmissions or content.</li>
          </ul>

          <h2>8. Governing Law</h2>
          <p>These Terms are governed by the laws of [Insert Jurisdiction]. Any disputes arising under these Terms shall be subject to the exclusive jurisdiction of the courts in [Insert Jurisdiction].</p>

          <h2>9. Changes to Terms</h2>
          <p>We reserve the right to modify these Terms at any time. We will provide notice of changes by updating the "Effective Date" above. Your continued use of the App constitutes acceptance of the revised Terms.</p>

          <h2>10. Contact Information</h2>
          <p>For questions or concerns about these Terms, please contact us at:</p>
          <p><strong>Forest Flow Software S.R.L.</strong><br/>Email: support@forestflowsoft.com</p>
        </div>
      )}
    </div>
  );
};

export default TermsConditionsPage;

