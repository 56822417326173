import React, { useEffect, useState } from 'react';

const PrivacyPolicyPage = () => {
  const [currentLang, setCurrentLang] = useState('ro');

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedLang = localStorage.getItem('lang_privacy');
    if (storedLang) {
      setCurrentLang(storedLang);
    }
  }, []);

  const toggleLanguage = (lang) => {
    setCurrentLang(lang);
    localStorage.setItem('lang_privacy', lang);
  };

  const sliderStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    width: '80px',
    height: '30px',
    background: '#ccc',
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px',
    boxSizing: 'border-box',
    cursor: 'pointer',
    userSelect: 'none'
  };

  const circleStyle = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    background: 'green',
    transition: 'left 0.3s',
    left: currentLang === 'ro' ? '0px' : '50px'
  };

  const textStyle = (lang) => ({
    position: 'relative',
    zIndex: 1,
    color: currentLang === lang ? '#fff' : '#000',
    fontWeight: 'bold',
    fontSize: '12px'
  });

  return (
    <div style={{ position: 'relative', padding: "20px" }}>
      <div style={sliderStyle}>
        <div style={circleStyle}></div>
        <span onClick={() => toggleLanguage('ro')} style={textStyle('ro')}>RO</span>
        <span onClick={() => toggleLanguage('en')} style={textStyle('en')}>EN</span>
      </div>

      {currentLang === 'ro' && (
        <div className="ro">
          <h1>Politica de Confidențialitate</h1>
          <p><strong>Data Intrării În Vigoare:</strong> 15 decembrie 2024</p>
          <p>La Forest Flow Software S.R.L., ne angajăm să protejăm confidențialitatea dvs. Această Politică de Confidențialitate explică cum colectăm, utilizăm, divulgăm și protejăm informațiile dvs. atunci când utilizați aplicația Reach ("Aplicația").</p>

          <h2>1. Informațiile Colectate</h2>
          <h3>a. Informații Personale</h3>
          <p>Colectăm următoarele informații personale atunci când utilizați Aplicația:</p>
          <ul>
            <li>Adresa de email.</li>
            <li>Data de naștere.</li>
            <li>Preferințe alimentare bazate pe activitatea dvs. în Aplicație (de ex., glisarea la stânga sau la dreapta).</li>
          </ul>

          <h3>b. Informații Non-Personale</h3>
          <p>Putem colecta informații non-personale precum:</p>
          <ul>
            <li>Informații despre dispozitiv (de ex., tipul dispozitivului, sistemul de operare și versiunea aplicației).</li>
            <li>Date de utilizare (interacțiuni în cadrul Aplicației).</li>
          </ul>

          <h2>2. Cum Utilizăm Informațiile Dvs.</h2>
          <p>Utilizăm informațiile dvs. pentru:</p>
          <ul>
            <li>A furniza, opera și menține Aplicația.</li>
            <li>A personaliza experiența și recomandările dvs.</li>
            <li>A comunica cu dvs. despre actualizări, promoții sau modificări ale Aplicației.</li>
            <li>A analiza tendințele de utilizare pentru a îmbunătăți funcționalitatea Aplicației.</li>
          </ul>

          <h2>3. Divulgarea Informațiilor Dvs.</h2>
          <p>Nu vindem informațiile dvs. personale către terțe părți. Cu toate acestea, putem divulga informațiile dvs. către:</p>
          <ul>
            <li><strong>Furnizori de Servicii:</strong> Terțe părți care ne ajută să operăm Aplicația.</li>
            <li><strong>Autorități Legale:</strong> Când este cerut de lege sau pentru a ne proteja drepturile legale.</li>
          </ul>

          <h2>4. Păstrarea Datelor</h2>
          <p>Păstrăm informațiile dvs. personale doar atât timp cât este necesar pentru a atinge scopurile descrise în această Politică de Confidențialitate, cu excepția cazului în care o perioadă mai lungă de păstrare este cerută de lege.</p>

          <h2>5. Securitatea Datelor</h2>
          <p>Implementăm măsuri de securitate standard în industrie pentru a proteja informațiile dvs. Cu toate acestea, nicio metodă de transmisie electronică sau de stocare nu este complet sigură, și nu putem garanta securitatea absolută.</p>

          <h2>6. Drepturile Dvs.</h2>
          <p>În funcție de jurisdicția dvs., este posibil să aveți următoarele drepturi:</p>
          <ul>
            <li>Acces la informațiile dvs. personale.</li>
            <li>Solicitarea corectării informațiilor inexacte sau incomplete.</li>
            <li>Solicitarea ștergerii informațiilor dvs. personale.</li>
            <li>Opoziția sau restricționarea procesării datelor dvs.</li>
          </ul>
          <p>Pentru a exercita aceste drepturi, vă rugăm să ne contactați la <a href="mailto:support@forestflowsoft.com">support@forestflowsoft.com</a>.</p>

          <h2>7. Confidențialitatea Copiilor</h2>
          <p>Aplicația nu este destinată persoanelor sub 13 ani și nu colectăm cu bună știință informații personale de la copii sub 13 ani. Dacă aflăm că am colectat informații de la un copil sub 13 ani, le vom șterge.</p>

          <h2>8. Modificări ale Politicii de Confidențialitate</h2>
          <p>Este posibil să actualizăm această Politică de Confidențialitate din când în când. Vom notifica schimbările.</p>

          <h2>9. Informații de Contact</h2>
          <p>Pentru întrebări sau nelămuriri legate de Politica de Confidențialitate, vă rugăm să ne contactați la:</p>
          <p><strong>Forest Flow Software S.R.L.</strong><br/>Email: <a href="mailto:privacy@forestflowsoft.com">privacy@forestflowsoft.com</a></p>
        </div>
      )}

      {currentLang === 'en' && (
        <div className="en">
          <h1>Privacy Policy</h1>
          <p><strong>Effective Date:</strong> December 15, 2024</p>
          <p>At Forest Flow Software S.R.L., we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use the Reach application ("App").</p>

          <h2>1. Information We Collect</h2>
          <h3>a. Personal Information</h3>
          <p>We collect the following personal information when you use the App:</p>
          <ul>
            <li>Email address.</li>
            <li>Day of birth</li>
            <li>Food preferences based on your activity within the App (e.g., swiping left or right).</li>
          </ul>

          <h3>b. Non-Personal Information</h3>
          <p>We may collect non-personal information such as:</p>
          <ul>
            <li>Device information (e.g., device type, operating system, and app version).</li>
            <li>Usage data (e.g., interactions within the App).</li>
          </ul>

          <h2>2. How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide, operate, and maintain the App.</li>
            <li>Personalize your experience and recommendations.</li>
            <li>Communicate with you about updates, promotions, or changes to the App.</li>
            <li>Analyze usage trends to improve the App’s functionality.</li>
          </ul>

          <h2>3. Sharing Your Information</h2>
          <p>We do not sell your personal information to third parties. However, we may share your information with:</p>
          <ul>
            <li><strong>Service Providers:</strong> Third parties that assist us in operating the App (e.g., hosting services).</li>
            <li><strong>Legal Authorities:</strong> When required by law or to protect our legal rights.</li>
          </ul>

          <h2>4. Data Retention</h2>
          <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required by law.</p>

          <h2>5. Data Security</h2>
          <p>We implement industry-standard security measures to protect your information. However, no method of electronic transmission or storage is completely secure, and we cannot guarantee absolute security.</p>

          <h2>6. Your Rights</h2>
          <p>Depending on your jurisdiction, you may have the following rights:</p>
          <ul>
            <li>Access your personal information.</li>
            <li>Request correction of inaccurate or incomplete information.</li>
            <li>Request deletion of your personal information.</li>
            <li>Object to or restrict the processing of your data.</li>
          </ul>
          <p>To exercise these rights, please contact us at <a href="mailto:support@forestflowsoft.com">support@forestflowsoft.com</a>.</p>

          <h2>7. Children’s Privacy</h2>
          <p>The App is not intended for individuals under the age of 13, and we do not knowingly collect personal information from children under 13. If we become aware that we have collected information from a child under 13, we will delete it.</p>

          <h2>8. Changes to this Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. We will notify you of significant changes by updating the "Effective Date" above. Your continued use of the App constitutes acceptance of the revised Privacy Policy.</p>

          <h2>9. Contact Information</h2>
          <p>If you have questions about this Privacy Policy, please contact us at:</p>
          <p><strong>Forest Flow Software S.R.L.</strong><br/>Email: <a href="mailto:privacy@forestflowsoft.com">privacy@forestflowsoft.com</a></p>
        </div>
      )}
    </div>
  );
};

export default PrivacyPolicyPage;
